<!-- scholarMag -->
<template>
  <div class="scholarMag w center_all">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>邮件推送</li>
        <li>-></li>
        <li>选择推送学者</li>
      </ul>
      <div>
        <div class="issue-export-box">
          <div class="text">导入学者：</div>
          <div class="issue-export">
            <el-upload ref="uploadIssueExportExcel" name="file" :auto-upload="true"
              :before-upload="beforeIssueExportUploadFile" :on-success="handleIssueExportSuccess"
              :data="issueExportForm" class="upload-issueExport" :action="IssueExportUploadUrl" accept=".xls, .xlsx">
              <el-button><i class="el-icon-upload2"></i> 导入学者</el-button>
            </el-upload>
            <a href="#" @click="handleIssueExportDownloadSample">下载模板</a>
          </div>
        </div>
        <div class="quick-choose-box">
          <div class="text">快捷选择：</div>
          <div class="btns-box">
            <div class="btn-item" v-for="(item, index) in btnList" :key="index" @click="typeBtnChange(item)">
              <el-button size="small" :type="pushType === item.id ? 'primary' : ''">
                {{ item.text }}
              </el-button>
            </div>
            <div class="text">历史版本：</div>
            <el-select v-model="historyVersion" class="history_version" placeholder="请选择">
              <el-option v-for="option in historyOptions" :key="option.value" :label="option.label + getPushType(option.pushType)"
                :value="option.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="push-box">
        <div class="push-box-header" v-if="pushType">
          <div class="scholar-source part">
            <div class="title-box">
              <div class="title">学者来源</div>
            </div>
          </div>
          <div class="article-scope part">
            <div class="title-box">
              <div class="title">论文范围</div>
              <div class="second-title">
                <div>本刊</div>
                <div>本期</div>
              </div>
            </div>
          </div>
          <div class="article-behavior part">
            <div class="title-box">
              <div class="title">学者行为</div>
              <div class="second-title">
                <div>全量</div>
                <div>激活</div>
                <div>阅读过文章</div>
                <div>打开率</div>
              </div>
            </div>
          </div>
          <div class="article-feature last-part part">
            <div class="title-box">
              <div class="title">学者特征</div>
              <div class="second-title">
                <div>阅读过关键词</div>
                <div>学者关键词</div>
              </div>
            </div>
          </div>
        </div>
        <div style="display:flex" v-if="pushType">
          <div class="push-box-check">
            <div class="push-box-check-item" v-for="(item, index) in finalCustomPushList" :key="index">
              <div class="radio scholar-source">
                {{ index + 1 }}
                <el-checkbox-group v-model="checkList0" @change="checkList0Change($event, index)">
                  <el-checkbox :label="'1' + index">{{ item[0].name }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="radio two-radio" @click="handleClick(index, 'article')">
                <el-checkbox-group v-model="checkList1[index]" :style="getNoEventsStyle(index)"
                  @change="checkListChange">
                  <el-checkbox v-for="(it, inx) in 2" :key="inx" :label="'1' + inx"
                    :disabled="item[1]['status' + inx] == -1"
                    @change="checkChange($event, index, inx, 1)"><br /></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="radio three-radio" @click="handleClick(index)">
                <el-checkbox-group v-model="checkList2[index]" :style="getNoEventsStyle(index)"
                  @change="checkListChange">
                  <el-checkbox v-for="(it, inx) in 4" :key="inx" :label="'1' + inx"
                    @change="checkChange($event, index, inx, 2)"><br /></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="radio article-feature" @click="handleClick(index)">
                <el-checkbox-group v-model="checkList3[index]" :style="getNoEventsStyle(index)"
                  @change="checkListChange">
                  <el-checkbox v-for="(it, inx) in 2" :key="inx" :label="'1' + inx"
                    @change="checkChange($event, index, inx, 3)"><br /></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
        <div class="author-mag" v-if="showAuthorMag && pushType">
          <el-checkbox v-model="checked" @change="checkboxChange"></el-checkbox>
          <div class="title ml-20">学者中图分类(引用引证刊)</div>
          <div class="author-mag-tree">
            <el-cascader ref="cascader" v-model="chooseAuthorMagList" :props="{ multiple: true, checkStrictly: true }"
              :options="authorMagList" clearable></el-cascader>
          </div>
        </div>
        <div class="btns">
          <el-button @click="setCustomScholar">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetTaskInfo } from '@/api/publishResults'
import { IssueExportUploadUrl, IssueExportSampleDownload } from '@/api/selfPush'
import { GetCustomScholar, SetCustomScholar, GetCategory, SetCategory, GetSetCategory, UpdatePushType, GetPushTypeDefault, GetDefault, GetEmailConfig, GetHistoryVersions, GetVersion, FindLastTask, GetSendVersionGroupType } from '@/api/emailPush'
import { mapGetters } from 'vuex'
export default {
  name: 'scholarMag',
  data() {
    return {
      IssueExportUploadUrl,
      currentTask: '',
      issueExportForm: {
        taskId: ''
      },
      customPushList: '',
      finalCustomPushList: [],
      checkList0: [],
      checkList1: [],
      checkList2: [],
      checkList3: [],
      chooseMag: [],
      authorMagList: [],
      chooseAuthorMagList: [],
      showAuthorMag: false,
      btnList: [
        {
          text: '刊维度全量',
          id: 1
        },
        {
          text: '刊维度激活',
          id: 4
        },
        {
          text: '期维度全量',
          id: 5
        },
        {
          text: '期维度激活',
          id: 6
        },
        {
          text: '自定义推送',
          id: 10
        },
        {
          text: '白名单推送',
          id: 11
        }
      ],
      pushType: '',
      checked: false,
      historyVersion: '', // 2024-12-27新增历史版本
      historyOptions: [] // 2024-12-27新增历史版本选项
    }
  },
  props: {},
  components: {},
  computed: {
    ...mapGetters({
      magId: 'magId'
    })
  },
  mounted() {
    this.getTaskInfoData()
    this.getConfig()
    this.getHistoryVersions()
  },
  watch: {
    historyVersion(newVal) {
      //console.log('newVal', newVal)
      if (newVal) {
        this.loadHistoryVersion(newVal);
      }
    },
    magId(nv) {
      if (nv) {
        this.$router.push('/emailTaskManagement?activeIndex=4')
      }
    }
  },
  methods: {
    // 获取currentTask
    getTaskInfoData() {
      const params = {
        taskId: this.$route.query.taskId
      }
      GetTaskInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.currentTask = data
          this.pushType = this.currentTask.pushType
          if (this.pushType) {
            this.getCustomScholar()
          }
        }
      })
    },
    // 本期导入学者-上传文件之前的钩子, 参数为上传的文件,若返回false或者返回Promise且被reject,则停止上传
    beforeIssueExportUploadFile(file) {
      let fileType = ''
      let arr = ['xls', 'xlsx']
      let filePath = file.name
      let fileNameLength = file.name.lastIndexOf('.')
      if (fileNameLength != -1) {
        fileType = filePath.substring(fileNameLength + 1, filePath.length).toLowerCase()
      } else {
        fileType = ''
      }
      if (!arr.includes(fileType)) {
        this.$message({
          message: '请上传' + arr + '类型文件！',
          duration: 1500,
          type: 'error'
        })
        return false
      }
      this.issueExportForm.taskId = this.$route.query.taskId
    },
    // 本期导入学者-文件上传成功时的钩子
    handleIssueExportSuccess(res, file, fileList) {
      this.$refs.uploadIssueExportExcel.clearFiles() // 清除上次上传记录
      if (res && res.status === 0) {
        let text = '成功导入学者' + res.data + '人'
        this.$message.success(text)
        this.pushType = 7 //本期导入学者 7
      } else {
        const { message = '' } = res
        fileList.pop()
        this.$message.error(message)
      }
    },
    // 本期导入学者-下载模板
    handleIssueExportDownloadSample() {
      window.open(`${IssueExportSampleDownload}?code=PUSH_AUTHOR`, '_self')
    },
    getCustomScholar(taskId = '') {
      this.showAuthorMag = false
      this.finalCustomPushList = []
      if (!taskId || taskId === '' || taskId === '0') {
        taskId = this.$route.query.taskId
      }
      let params = {
        historyId: taskId
      }
      GetCustomScholar(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          if (data && data.length > 0) {
            this.customPushList = data
            this.handleFinalCustomPushList()
            if (this.showAuthorMag) {
              this.getCategoryData()
            }
          } else {
            this.$message.error('暂无数据')
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleFinalCustomPushList() {
      this.checkList0 = []
      this.checkList1 = []
      this.checkList2 = []
      this.checkList3 = []
      this.customPushList.map((item, index) => {
        // status 状态 0.不选 1.选择 -1.禁用
        if ((item.name === '引用论文作者' && item.status === 1) || (item.name === '引证论文作者' && item.status === 1)) {
          this.showAuthorMag = true
        }
        if (item.status == 1) {
          this.checkList0.push(item.status + '' + index)
        }
        let arr = [
          {
            name: item.name,
            status: item.status + '' + index
          },
          {
            name: '论文范围',
            check: [],
            status0: '', //本刊
            status1: '' //本期
          },
          {
            name: '学者行为',
            check: [],
            status0: '', //全量
            status1: '', //激活
            status2: '', //阅读过文章
            status3: '' //打开率
          },
          {
            name: '学者特征',
            check: [],
            status0: '', //阅读过关键词
            status1: '' //学者关键词
          }
        ]
        item.groupList.map((it, index) => {
          it.choiceList.map((i, num) => {
            if (i.status === 1) {
              arr[index + 1].check.push(1 + '' + num)
            }
            arr[index + 1]['status' + num] = i.status
          })
        })
        this.$set(this.finalCustomPushList, index, arr)
      })
      this.finalCustomPushList.map((item, index) => {
        this.checkList1.push(item[1].check)
        this.checkList2.push(item[2].check)
        this.checkList3.push(item[3].check)
      })
    },
    // 自定义学者确定
    setCustomScholar() {
      this.setCategory()
      this.pushTypeChange()
      this.handleCustomPushList()
      SetCustomScholar(this.customPushList, this.$route.query.taskId).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '设置成功'
          })
          // this.$router.push({
          //   path: '/emailTaskManagement'
          // })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleCustomPushList() {
      // 将checkbox状态全部置0
      this.customPushList.map((item) => {
        item.status = 0
        item.groupList[1].choiceList.map((it, inx) => {
          it.status = 0
        })
        item.groupList[2].choiceList.map((it, inx) => {
          it.status = 0
        })
      })
      // 处理学者来源数据
      this.checkList0.map((item, index) => {
        if (item.slice(0, 1) == 1) {
          this.customPushList[Number(item.substr(1))].status = 1
        }
      })
      // 处理论文范围数据
      this.checkList1.map((item, index) => {
        if (item.length > 0) {
          if (item.indexOf('10') !== -1) {
            this.customPushList[index].groupList[0].choiceList[0].status = 1
          } else {
            let status = this.customPushList[index].groupList[0].choiceList[0].status
            this.customPushList[index].groupList[0].choiceList[0].status = status === -1 ? -1 : 0
          }
          if (item.indexOf('11') !== -1) {
            this.customPushList[index].groupList[0].choiceList[1].status = 1
          } else {
            let status = this.customPushList[index].groupList[0].choiceList[1].status
            this.customPushList[index].groupList[0].choiceList[1].status = status === -1 ? -1 : 0
          }
        } else {
          if (this.customPushList[index] && this.customPushList[index].groupList && this.customPushList[index].groupList[0] && this.customPushList[index].groupList[0].choiceList) {
            this.customPushList[index].groupList[0].choiceList[0].status = 0
            this.customPushList[index].groupList[0].choiceList[1].status = 0
          }
        }
      })
      // 处理学者行为数据
      this.checkList2.map((item, index) => {
        if (item && item[0]) {
          this.customPushList[index].groupList[1].choiceList.map((it, inx) => {
            item.map((i, num) => {
              if (inx === Number(i.substr(1))) {
                it.status = 1
              }
            })
          })
        }
      })
      // 处理学者特征数据
      this.checkList3.map((item, index) => {
        if (item.length > 0) {
          this.customPushList[index].groupList[2].choiceList.map((it, inx) => {
            item.map((i, num) => {
              if (inx === Number(i.substr(1))) {
                it.status = 1
              }
            })
          })
        }
      })
    },
    getNoEventsStyle(index) {
      if (this.checkList0.indexOf('1' + index) === -1) {
        return {
          'pointer-events': 'none'
        }
      }
    },
    handleClick(index, type) {
      if (type === 'article' && index > 3) return
      if (this.checkList0.indexOf('1' + index) === -1) {
        this.$message.error('当前学者来源未选择，此选项禁用')
      }
    },
    // 学者领域tree图
    getCategoryData() {
      let params = {
        historyId: this.$route.query.taskId
      }
      GetCategory(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.authorMagList = data
          this.getSetCategory()
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 学者领域tree回显
    getSetCategory(categoryId) {
      let params = {
        historyId: this.$route.query.taskId,
        categoryId: categoryId || ''
      }
      GetSetCategory(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data) {
            this.chooseAuthorMagList = data
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    checkboxChange(val) {
      if (val) {
        let params = {
          historyId: this.$route.query.taskId
        }
        GetDefault(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            if (data) {
              this.chooseAuthorMagList = data
            }
          } else {
            const { message = '' } = res.data
            this.$message.error(message)
          }
        })
      } else {
        this.chooseAuthorMagList = []
      }
    },
    // 学者领域tree设置
    setCategory() {
      this.$nextTick(() => {
        let ids = ''
        if (this.$refs.cascader) {
          let arr = this.$refs.cascader.getCheckedNodes()
          if (arr.length > 0) {
            arr.map((item, index) => {
              ids = ids + item.data.id + (index === arr.length - 1 ? '' : ',')
            })
          }
          let params = {
            historyId: this.$route.query.taskId,
            categoryIds: ids
          }
          SetCategory(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
            }
          })
        }
      })
    },
    // 第一列复选框change
    checkList0Change(val, index) {
      const isChecked = val.includes('1' + index);
      // 取消选中时，将该行数据置空
      if (!isChecked) {
        this.$set(this.checkList1, index, []);
        this.$set(this.checkList2, index, []);
        this.$set(this.checkList3, index, []);
      } else {
        if (this.pushType != 8) {
          this.pushType = 10

          // 论文范围处理
          // 发文作者、机构学者、白名单、编委、关键字采集
          if (index === 0 || index === 3 || index === 4 || index === 5 || index === 6 || index === 8 || index === 9 || index === 19 || index === 20) {
            this.$set(this.checkList1, index, ['10'])
          }
          // 引证论文作者、关键词匹配、本期导入学者
          if (index === 2 || index === 7 || index === 10) {
            this.$set(this.checkList1, index, ['11'])
          }

          if (index === 1 || index === 8 || index === 9 || index === 10 || index === 19 || index === 20) {
          } else {
            this.$set(this.checkList2, index, ['11'])
          }
        }
      }
    },
    // 复选框change
    checkListChange() {
      if (this.pushType != 8) {
        this.pushType = 10
      }
    },
    checkChange(event, index, inx, groupOrder) {
      let currentGroup = this[`checkList` + groupOrder][index];
      // 如果当前复选框被选中
      if (event) {
        // 创建一个新的数组，只包含当前选中的复选框
        currentGroup[index] = ["1" + inx];
      } else {
        // 如果当前复选框被取消选中，清空当前组中的所有选中项
        currentGroup[index] = [];
      }

      // 更新当前组的状态
      this.$set(this[`checkList` + groupOrder], index, currentGroup[index]);
    },
    // 设置推送学者类型
    pushTypeChange() {
      let params = {
        id: this.$route.query.taskId,
        pushType: this.pushType
      }
      UpdatePushType(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 推送学者类型切换
    typeBtnChange(item) {
      let historyObj = this.historyOptions.find(option => option.pushType === item.id)
      if (historyObj) {
        this.historyVersion = historyObj.value
      } else {
        this.historyVersion = ''
        this.pushType = item.id
        this.getPushTypeDefault()
      }
    },
    // 各推送类型默认设置
    getPushTypeDefault() {
      let params = {
        pushType: this.pushType
      }
      GetPushTypeDefault(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.customPushList = res.data.data
          this.handleFinalCustomPushList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 获取当前配置
    getConfig() {
      let params = {
        magId: this.magId
      }
      GetEmailConfig(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          if (data.smartPush == 1) {
            this.btnList.push({
              text: '智能推荐',
              id: 8
            })
          }
        }
      })
    },
    async getHistoryVersions() {
      try {
        let historys = await this.getHistoryVersionsByTaskId(this.$route.query.taskId);
        if (historys.length > 0) {
          this.historyOptions = historys;
        } else {
          let lastTaskId = await this.findLastTask(this.$route.query.taskId);
          if (lastTaskId !== undefined && lastTaskId !== null) {
            historys = await this.getHistoryVersionsByTaskId(lastTaskId);
            this.historyOptions = historys;
          } else {
            this.historyOptions = [];
          }
        }
      } catch (error) {
        console.error('获取历史版本失败:', error);
        this.$message.error('获取历史版本失败');
      }
    },
    async findLastTask(taskId) {
      if (!taskId) {
        console.error('findLastTask: taskId 无效');
        return null;
      }
      let params = {
        historyId: taskId
      }
      const res = await FindLastTask(params);
      console.log('findLastTask: res', res);
      try {
        if (res && res.data && res.data.status === 0) {
          console.log('lastTaskId-data', res.data.data)
          return res.data.data;
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      } catch (error) {
        console.error('获取上一个任务ID失败:', error)
        this.$message.error('获取上一个任务ID失败')
      }
      return null;
    },
    async getHistoryVersionsByTaskId(taskId) {
      if (!taskId) {
        console.error('getHistoryVersionsByTaskId: taskId 无效');
        return [];
      }
      // let params = {
      //   taskId: taskId
      // }
      // const res = await GetHistoryVersions(params);
      const res = await GetSendVersionGroupType({magId:this.magId})
      // 假设有一个 API 方法 GetHistoryVersions 可以获取历史版本数据
      try {
        if (res && res.data && res.data.status === 0) {
          let historys = res.data.data.map(option => {
            return {
              label: this.formatTimestamp(Number(option.version)),
              value:  option.historyId + "_" + option.version,
              pushType: option.pushType
            }
          })
          return historys;
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      } catch (error) {
        console.error('获取历史版本失败:', error)
        this.$message.error('获取历史版本失败')
      }
      return [];
    },
    getPushType(pushType) {
      let pushTypeObj = this.btnList.find(item => item.id === pushType)
      return pushTypeObj ? '-' + pushTypeObj?.text : ''
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    loadHistoryVersion(uuid) {
      // 将uuid通过"_"分割 获取taskId和uuid
      let [taskId, timestamp] = uuid.split('_')
      // 根据当前版本选中对应的快捷选择按钮
      if (this.historyOptions.find(item => item.value === uuid)) {
        this.pushType = this.historyOptions.find(item => item.value === uuid)?.pushType || ''
      }
      let params = {
        historyId: taskId,
        uuid: timestamp
      }
      // 调用GetVersion方法获取历史版本数据
      GetVersion(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          if (res.data.data && res.data.data.length > 0) {
            this.customPushList = res.data.data
            this.handleFinalCustomPushList()
            if (this.showAuthorMag) {
              this.getCategoryData()
            }
          } else {
            this.customPushList = []
            this.finalCustomPushList = []
            this.$message.error('暂无数据')
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.scholarMag {
  .text {
    line-height: 32px;
  }
  .history_version {
    width: 250px;
  }
  .push-box {
    .scholar-source .el-checkbox-group {
      width: 50%;
    }

    .el-checkbox {
      display: block;
      text-align: left;
    }

    .el-radio {
      width: 50%;
      margin-right: 0;
    }

    .two-radio {
      .el-radio {
        width: 50%;
      }

      .el-checkbox-group {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        .el-checkbox {
          text-align: center;
          width: 25%;
          margin: auto;
        }
      }
    }

    .three-radio {
      .el-radio {
        width: 33%;
      }

      .el-checkbox-group {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        .el-checkbox {
          text-align: center;
          width: 25%;
          margin: auto;
        }
      }
    }

    .article-feature {
      .radio {
        margin-top: 10px !important;
      }

      .el-checkbox-group {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .el-checkbox {
          width: 50%;
          text-align: center;
          margin: 10px 0;
        }
      }
    }

    .el-radio-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .author-mag {
      .el-input {
        .el-input__inner {
          width: 900px;
        }
      }
    }

    .radio {
      .el-radio__label {
        display: none;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.scholarMag {
  .issue-export-box {
    display: flex;

    .issue-export {
      margin-left: 20px;

      a {
        margin-left: 20px;
      }

      .upload-issueExport {
        display: inline-block;
      }
    }
  }

  .quick-choose-box {
    display: flex;
    margin-top: 20px;

    .btns-box {
      display: flex;
      margin-left: 20px;

      .btn-item {
        margin-right: 20px;
      }
    }
  }

  .push-box {
    margin-top: 30px;

    .push-box-header {
      display: flex;
      justify-content: space-between;
    }

    .part {
      border-right: 1px solid #666;
      width: 25%;
    }

    .last-part {
      border-right: 0 !important;
    }

    .article-behavior {
      .second-title {

        div:nth-child(1),
        div:nth-child(2) {
          width: 25%;
        }
      }
    }

    .article-feature {
      border-right: 0 !important;
    }

    .title-box {
      border-bottom: 1px solid #666;
      height: 60px;
      padding: 0 10px;

      .title {
        font-size: 16px;
        font-weight: bold;
      }

      .second-title {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 4px;
      }
    }

    .radio {
      border-right: 1px solid #666;
    }

    .push-box-check {
      flex: 1;

      .push-box-check-item {
        display: flex;

        .radio {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }

        .scholar-source {
          // justify-content: left;
        }
      }
    }

    .author-mag {
      display: flex;
      align-items: center;
      margin: 20px 0 100px 0;

      .title {
        margin-right: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #333;
      }
    }

    .btns {
      margin-top: 20px;
    }
  }

  .noEvents {
    pointer-events: none;
  }
}
</style>
